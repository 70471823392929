import Divider from "components/Divider";
import Dropdown from "components/Dropdown";
import { RadioGroup } from "components/RadioGroup";
import { TextInput } from "components/TextInput";
import { removeWhitespace } from "helpers/string";
import { FC, useEffect } from "react";
import {
  FormState,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { KommunList } from "types/Kommun.type";
import { useGuessKommun } from "api/postnummer.api";
import { TransportPlaneringDTO } from "pages/app/naturvardsverket/ombuds-rapportering/ombudsRapportering.type";

type Props = {
  register: UseFormRegister<TransportPlaneringDTO>;
  formState: FormState<TransportPlaneringDTO>;
  watch: UseFormWatch<TransportPlaneringDTO>;
  setValue: UseFormSetValue<TransportPlaneringDTO>;
  disabled?: boolean;
  errorList: string[];
};

export const Mottagare: FC<Props> = ({
  register,
  formState,
  watch,
  setValue,
  disabled = false,
  errorList,
}) => {
  const mottagningsplatsLocationType = watch("mottagningsplatsLocationType");
  const mottagningsplatsPostnummer = watch(
    "mottagningsplats.adress.postnummer"
  );

  // Gissa postnummer
  const gissaMottagareKommun = useGuessKommun();

  useEffect(() => {
    if (gissaMottagareKommun.data) {
      setValue("mottagningsplats.kommunkod", gissaMottagareKommun.data);
    }
  }, [gissaMottagareKommun.data, setValue]);

  const kommunError =
    errorList.some((e) => e.includes("mottagningsplats.kommunkod")) ||
    !!formState.errors.mottagningsplats?.kommunkod?.message
      ? "Vänligen ange en kommun"
      : undefined;

  return (
    <div className="px-6">
      <h2 className="text-gray-900 text-base font-medium mb-4">Mottagare</h2>
      <div className="space-y-4">
        <TextInput
          register={register}
          label="Organisationsnummer"
          name="nyInnehavare"
          errorMessage={formState?.errors?.nyInnehavare?.message}
          disabled={disabled}
        />

        <Divider />

        <RadioGroup
          register={register}
          name="mottagningsplatsLocationType"
          items={[
            { label: "Adress", value: "address", disabled },
            { label: "Koordinater", value: "coordinate", disabled },
          ]}
          row
        />
        {mottagningsplatsLocationType === "address" ? (
          <>
            <TextInput
              key={"mottagningsplats.adress.adressrad"}
              register={register}
              label="Adress"
              name="mottagningsplats.adress.adressrad"
              placeholder="Exempelvägen 123"
              errorMessage={
                formState?.errors?.mottagningsplats?.adress?.adressrad?.message
              }
              disabled={disabled}
            />
            <TextInput
              key={"mottagningsplats.adress.postnummer"}
              register={register}
              label="Postnummer"
              name="mottagningsplats.adress.postnummer"
              errorMessage={
                formState?.errors?.mottagningsplats?.adress?.postnummer?.message
              }
              disabled={disabled}
            />
          </>
        ) : (
          <>
            <TextInput
              key="mottagningsplats.koordinat.nposition"
              placeholder="1234567"
              register={register}
              label="N (SWEREF 99)"
              name="mottagningsplats.koordinat.nposition"
              errorMessage={
                formState?.errors?.mottagningsplats?.koordinat?.nposition
                  ?.message
              }
              disabled={disabled}
              // link="https://rl.se/rt90"
              // linkText="Hitta"
            />
            <TextInput
              key="mottagningsplats.koordinat.eposition"
              register={register}
              label="E (SWEREF 99)"
              placeholder="123456"
              name="mottagningsplats.koordinat.eposition"
              errorMessage={
                formState?.errors?.mottagningsplats?.koordinat?.eposition
                  ?.message
              }
              disabled={disabled}
            />
            {/* <TextInput
                    key={"transportSlutplats.koordinat.beskrivning"}
                    register={register}
                    label="Beskrivning"
                    name="transportSlutplats.koordinat.beskrivning"
                  /> */}
          </>
        )}
        <Divider />
        <Dropdown
          items={KommunList.map((k) => ({
            label: k.label,
            value: k.code,
          }))}
          label="Kommun"
          name="mottagningsplats.kommunkod"
          register={register}
          action={
            !(
              removeWhitespace(mottagningsplatsPostnummer ?? "").length === 5
            ) || disabled
              ? undefined
              : () =>
                  gissaMottagareKommun.mutate({
                    postnummer: removeWhitespace(mottagningsplatsPostnummer),
                  })
          }
          actionText="Beräkna"
          errorMessage={kommunError}
          isActionLoading={gissaMottagareKommun.isLoading}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
