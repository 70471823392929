import { FC, Fragment } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import {
  // BackwardIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import { classNames } from "helpers/classNames";

export interface ActionMenuItem {
  label: string;
  onClick: () => void;
  icon: React.ReactNode;
  isHidden?: boolean;
  isLoading?: boolean;
  isNew?: boolean;
  isDanger?: boolean;
}
interface Props {
  items: ActionMenuItem[];
  isSmall?: boolean;
}
export const ActionMenu: FC<Props> = ({ items, isSmall }) => {
  return (
    <Menu as="div" className="inline-block text-left">
      <div>
        <MenuButton className="-m-2 flex items-center rounded-full p-0.5 text-gray-500 hover:text-gray-600 border-2 border-gray-200 outline-none">
          <span className="sr-only">Open options</span>
          <EllipsisHorizontalIcon
            className={classNames(isSmall ? "h-3.5 w-3.5" : "h-5 w-5")}
            aria-hidden="true"
          />
        </MenuButton>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-20"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-cyan ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {items
              .filter((item) => !item.isHidden)
              .map(({ label, onClick, icon, isLoading, isDanger }) => (
                <MenuItem key={label}>
                  <button
                    className={classNames(
                      "group flex items-center px-4 py-2 text-sm relative",
                      isDanger
                        ? "text-rose-700 hover:text-rose-800"
                        : "text-gray-700 hover:text-gray-900"
                    )}
                    onClick={onClick}
                  >
                    {icon}
                    {label}
                    {isLoading && (
                      <svg
                        className={"w-4 h-4 ml-2 text-cyan-500 animate-spin"}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                  </button>
                </MenuItem>
              ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
};
