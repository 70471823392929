import { FC, Fragment } from "react";
import { Menu, MenuButton, MenuItem, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import { classNames } from "helpers/classNames";

export interface ActionMenuItem {
  label: string;
  onClick: () => void;
  icon: React.ReactNode;
  isHidden?: boolean;
}
interface Props {
  items: ActionMenuItem[];
  isSmall?: boolean;
}

export const DotMenu: FC<Props> = ({ items, isSmall }) => {
  return (
    <Menu as="div" className="relative flex-none">
      <MenuButton className="-m-2 flex items-center rounded-full p-0.5 text-gray-500 hover:text-gray-600 border-2 border-gray-200 outline-none">
        <span className="sr-only">Open options</span>
        <EllipsisHorizontalIcon
          className={classNames(isSmall ? "h-3.5 w-3.5" : "h-5 w-5")}
          aria-hidden="true"
        />
      </MenuButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white border border-gray-75 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          {items
            .filter(({ isHidden }) => !isHidden)
            .map(({ label, onClick, icon }) => (
              <MenuItem key={label}>
                <button
                  className={
                    "group flex items-center px-4 py-2  text-sm text-gray-700 hover:text-gray-900 relative"
                  }
                  onClick={onClick}
                >
                  {icon}
                  {label}
                </button>
              </MenuItem>
            ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
