import { ModalContentWrapper } from "components/ModalContentWrapper";
import { getWeek } from "date-fns";
import { sv as swedish } from "date-fns/locale";
import { getAssignmentTypeName } from "enums/AssignmentType.enum";
import { FC, useEffect, useState } from "react";
import { Link } from "components/Link";
import { appRoutes } from "routes.type";
import { Assignment, hasInspection } from "types/assignment.type";
import Alert from "components/Alert";

interface Props {
  assignment?: Assignment;
}

export const DeleteAssignment: FC<Props> = ({ assignment }) => {
  const [localAssignment, setLocalAssignment] = useState<Assignment>();
  useEffect(() => {
    if (assignment) {
      setLocalAssignment(assignment);
    }
  }, [assignment]);
  if (!localAssignment) {
    return null;
  }
  return (
    <ModalContentWrapper>
      <div className="p-4 rounded-md" style={{ backgroundColor: "#fafbfb" }}>
        <Link
          to={`${appRoutes.customers.fullPath}/${assignment?.customer?.id}`}
          // target="_blank"
          // rel="noopener noreferrer"
          className="text-cyan-600 hover:text-cyan-700 font-medium"
        >
          {localAssignment.customer?.name}
        </Link>
        <div className="flex items-center mt-2">
          <p className="leading-6 text-sm font-base text-gray-700 mr-1">{`${getAssignmentTypeName(
            localAssignment.type
          )} av objekt `}</p>
          <Link
            to={`${appRoutes.customers.fullPath}/${localAssignment.customer?.id}/${localAssignment.separator.id}`}
            // target="_blank"
            // rel="noopener noreferrer"
            className="text-cyan-600 hover:text-cyan-700 text-sm font-medium"
          >
            {localAssignment.separator.identifier}
          </Link>
        </div>
        <div className="flex items-center mt-2">
          <p className="leading-6 text-sm font-base text-gray-700 mr-1">{`${
            localAssignment.user.name
          }, vecka ${getWeek(new Date(localAssignment.date ?? ""), {
            locale: swedish,
          })}`}</p>
        </div>
      </div>
      {hasInspection(localAssignment) && (
        <Alert text="Även rapporten som hör till detta uppdrag kommer tas bort." />
      )}
    </ModalContentWrapper>
  );
};
