import Divider from "components/Divider";
import Dropdown from "components/Dropdown";
import { RadioGroup } from "components/RadioGroup";
import { TextInput } from "components/TextInput";
import { removeWhitespace } from "helpers/string";
import { FC, useEffect } from "react";
import {
  FormState,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { KommunList } from "types/Kommun.type";
import { useGuessKommun } from "api/postnummer.api";
import { TransportPlaneringDTO } from "pages/app/naturvardsverket/ombuds-rapportering/ombudsRapportering.type";
import { getSeparatorLabel } from "types/separator/separator.helpers";

type Props = {
  register: UseFormRegister<TransportPlaneringDTO>;
  formState: FormState<TransportPlaneringDTO>;
  watch: UseFormWatch<TransportPlaneringDTO>;
  errorList: string[];
  setValue: UseFormSetValue<TransportPlaneringDTO>;
  disabled?: boolean;
};

export const Uppkomstplats: FC<Props> = ({
  register,
  formState,
  watch,
  errorList,
  setValue,
  disabled = false,
}) => {
  const uppkomstplatsLocationType = watch("uppkomstplatsLocationType");
  const startPlatsPostnummer = watch("uppkomstplats.adress.postnummer");

  // Gissa postnummer
  const gissaSeparatorKommun = useGuessKommun();
  useEffect(() => {
    if (gissaSeparatorKommun.data) {
      setValue("uppkomstplats.kommunkod", gissaSeparatorKommun.data);
    }
  }, [gissaSeparatorKommun.data, setValue]);

  const kommunError =
    errorList.some((e) => e.includes("uppkomstplats.kommunkod")) ||
    !!formState.errors.uppkomstplats?.kommunkod?.message
      ? "Vänligen ange en kommun"
      : undefined;

  return (
    <div className="px-6">
      <h2 className="text-gray-900 text-base font-medium mb-4">
        Avfallets uppkomstplats
      </h2>
      <div className="space-y-4">
        <TextInput
          register={register}
          label={`${getSeparatorLabel("cfarNR")} (om möjligt)`}
          name="cfarNR"
          link="https://cfarnrsok.scb.se/"
          linkText="Hitta"
          errorMessage={formState?.errors?.cfarNR?.message}
          disabled={disabled}
        />
        <Divider />

        <RadioGroup
          register={register}
          name="uppkomstplatsLocationType"
          items={[
            { label: "Adress", value: "address", disabled },
            { label: "Koordinater", value: "coordinate", disabled },
          ]}
          row
        />
        {uppkomstplatsLocationType === "address" ? (
          <>
            <TextInput
              key={"uppkomstplats.adress.adressrad"}
              register={register}
              label="Objektets adress"
              name="uppkomstplats.adress.adressrad"
              placeholder="Exempelvägen 123"
              errorMessage={
                formState?.errors?.uppkomstplats?.adress?.adressrad?.message
              }
              disabled={disabled}
            />
            <TextInput
              key={"uppkomstplats.adress.postnummer"}
              register={register}
              label="Objektets postnummer"
              name="uppkomstplats.adress.postnummer"
              errorMessage={
                formState?.errors?.uppkomstplats?.adress?.postnummer?.message
              }
              disabled={disabled}
            />
          </>
        ) : (
          <>
            <TextInput
              key="uppkomstplats.koordinat.nposition"
              register={register}
              label="N (SWEREF 99)"
              name="uppkomstplats.koordinat.nposition"
              placeholder="1234567"
              errorMessage={
                formState?.errors?.uppkomstplats?.koordinat?.nposition?.message
              }
              disabled={disabled}
            />
            <TextInput
              key="uppkomstplats.koordinat.eposition"
              register={register}
              placeholder="13456"
              label="E (SWEREF 99)"
              name="uppkomstplats.koordinat.eposition"
              errorMessage={
                formState?.errors?.uppkomstplats?.koordinat?.eposition?.message
              }
              disabled={disabled}
            />
          </>
        )}
        <Divider />

        <Dropdown
          items={KommunList.map((k) => ({
            label: k.label,
            value: k.code,
          }))}
          label="Kommun"
          name="uppkomstplats.kommunkod"
          register={register}
          disabled={disabled}
          errorMessage={kommunError}
          action={
            !(removeWhitespace(startPlatsPostnummer ?? "").length === 5) ||
            disabled
              ? undefined
              : () =>
                  gissaSeparatorKommun.mutate({
                    postnummer: removeWhitespace(startPlatsPostnummer),
                  })
          }
          actionText="Beräkna"
          isActionLoading={gissaSeparatorKommun.isLoading}
        />
      </div>
    </div>
  );
};
