import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { Role } from "types/enums";
import { User } from "types/user.type";

const userStore = create<UserState>()(
  persist(
    (set) => ({
      user: undefined,
      setUser: (user: User) =>
        set((state) => ({
          ...state,
          user,
        })),
      clearUser: () =>
        set((state) => ({
          ...state,
          user: undefined,
        })),
    }),
    {
      name: "user-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

interface UserState {
  user?: User;
  setUser: (user: User) => void;
  clearUser: () => void;
}

export default userStore;

export const getUserCompanyId = (state: UserState) =>
  state.user?.companyId ?? -1;
export const getUserRole = (state: UserState) => state.user?.role ?? Role.Guest;
export const getUserId = (state: UserState) => state.user?.id;

export const useIsAdmin = () => {
  const role = userStore(getUserRole);
  return role >= Role.Admin;
};
