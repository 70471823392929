import { TextInput } from "components/TextInput";
import { FC } from "react";
import { FormState, UseFormRegister } from "react-hook-form";
import { TransportPlaneringDTO } from "pages/app/naturvardsverket/ombuds-rapportering/ombudsRapportering.type";

type Props = {
  formState: FormState<TransportPlaneringDTO>;
  register: UseFormRegister<TransportPlaneringDTO>;
  disabled?: boolean;
};

export const Transportör: FC<Props> = ({ register, formState, disabled }) => {
  return (
    <div className="pl-6">
      <h2 className="text-gray-900 text-base font-medium mb-4">
        Transportör/ombud
      </h2>
      <div className="space-y-4">
        <TextInput
          register={register}
          label="Företagets namn"
          name="ombudetsNamn"
          errorMessage={formState?.errors?.ombudetsNamn?.message}
          disabled={disabled}
        />
        <TextInput
          register={register}
          label="Organisationsnummer"
          name="ombud"
          errorMessage={formState?.errors?.ombud?.message}
          disabled={disabled}
        />
        <TextInput
          register={register}
          label="Kontaktperson - namn"
          name="ombudetsKontaktpersonNamn"
          errorMessage={formState?.errors?.ombudetsKontaktpersonNamn?.message}
          disabled={disabled}
        />

        <TextInput
          register={register}
          label="Kontaktperson - epost"
          name="ombudetsKontaktpersonEpost"
          errorMessage={formState?.errors?.ombudetsKontaktpersonEpost?.message}
          disabled={disabled}
        />
        <TextInput
          register={register}
          label="Kontaktperson - telefon"
          name="ombudetsKontaktpersonTelefonnummer"
          errorMessage={
            formState?.errors?.ombudetsKontaktpersonTelefonnummer?.message
          }
          disabled={disabled}
        />
      </div>
    </div>
  );
};
