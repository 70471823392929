import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import axios from "axios";
import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from "react-hot-toast";

const queryClient = new QueryClient();
export const instance = axios.create();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <App />
      </Router>
      <Toaster position="top-right" toastOptions={{ duration: 2000 }} />
    </QueryClientProvider>
  </React.StrictMode>
);
