import { sv } from "date-fns/locale";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Path,
  Controller,
  useFormContext,
  useController,
} from "react-hook-form";

import { ModalLabel } from "components/ModalLabel";
import { twMerge } from "tailwind-merge";

registerLocale("sv", sv);

type FormDataType<T = any> = { [key: string]: T };

type Props<T extends FormDataType> =
  React.InputHTMLAttributes<HTMLInputElement> & {
    name: Path<T>;
    label?: string;
    // required?: boolean;
    placeholder?: string;
    maxDate?: Date;
    labelClassName?: string;
    isMonthPicker?: boolean;
    calendarIconClassName?: string;
    minDate?: Date;
  };

export function DatePickerNew<T extends FormDataType>(props: Props<T>) {
  const {
    name,
    label,
    // required,
    placeholder,
    maxDate,
    isMonthPicker,
    labelClassName,
    minDate,
    disabled,
  } = props;

  const {
    control,
    // formState: { errors },
    clearErrors,
  } = useFormContext<T>();

  const {
    field: { onBlur },
    fieldState: { error },
  } = useController({ control, name });

  return (
    <div className="relative flex flex-col">
      {!!label && (
        <ModalLabel
          className={twMerge(
            "mb-1",
            labelClassName,
            disabled ? "text-gray-400" : ""
          )}
        >
          {label}
        </ModalLabel>
      )}

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {/* <div className="relative h-0 w-0"> */}
            {/* <CalendarDaysIcon className="h-5 w-5 absolute left-2 top-8 z-50 text-gray-500" /> */}
            {/* </div> */}
            <ReactDatePicker
              onChange={(date) => {
                field.onChange(date);
                if (error) {
                  clearErrors(name);
                }
              }}
              onBlur={onBlur}
              selected={field.value}
              dateFormat={isMonthPicker ? "yyyy-MM" : "yyyy-MM-dd"}
              showMonthYearPicker={isMonthPicker}
              showFullMonthYearPicker={isMonthPicker}
              locale={"sv"}
              placeholderText={placeholder}
              isClearable
              disabled={disabled}
              maxDate={maxDate}
              minDate={minDate}
              className={twMerge(
                "rounded-md shadow-sm sm:text-sm disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 cursor-pointer",
                error
                  ? "border-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 pr-10 text-red-900 "
                  : "border-gray-300 focus:border-cyan-500 focus:ring-cyan-500"
              )}
            />
          </>
        )}
      />
      {error && <p className="mt-2 text-sm text-red-600">{error.message}</p>}
    </div>
  );
}
