import { useNotification } from "hooks/useNotification";
import { useMutation } from "react-query";
import instance from "./axiosInstance";

export const ENDPOINT = "/image/upload-many";

export const useUploadFiles = () => {
  const notification = useNotification();
  return useMutation(
    async (payload: File[]): Promise<string[]> => {
      const formData = new FormData();
      // console.log({ payload });
      payload.forEach((file) => formData.append("files", file));
      // console.log({ formData });
      return (
        await instance.post(ENDPOINT, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      ).data;
    },
    {
      onError: () => {
        notification.error("Något gick fel, kontakta support");
      },
    }
  );
};
