import { useVehicle } from "api/vehicle.api";
import { PageLoader } from "components/PageLoader";
import { useParams } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
export const VehicleContentQrPage = () => {
  const params = useParams();
  const id: number = +(params?.id || "-1");

  const { data: vehicle, isLoading } = useVehicle(+id);
  if (isLoading) {
    return <PageLoader />;
  }

  if (!vehicle || !vehicle.contentLink) {
    return <div>Error</div>;
  }

  return (
    <div className="p-16 bg-white flex-col items-center">
      <h1 className="text-7xl font-medium text-gray-900 mb-8">
        Godsförteckning
      </h1>
      <h2 className="text-3xl font mb-16 px-1.5">
        Scanna QR-kod med mobilkamera
      </h2>
      <div className="px-32 mb-16">
        <QRCodeSVG
          value={vehicle.contentLink}
          height={600}
          width={600}
          className="h-96 w-96"
        />
      </div>
      <a
        className="text-cyan-600 hover:text-cyan-700 font-medium"
        href={vehicle.contentLink}
      >
        {vehicle.contentLink}
      </a>
    </div>
  );
};
