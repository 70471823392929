import { create } from "zustand";

const menuStore = create<MenuState>()((set) => ({
  menu: [] as MenuItem[],
  currentCustomerName: "",
  setCurrentCustomerName: (name: string) =>
    set((state) => ({ ...state, currentCustomerName: name })),
  currentWasteStationName: "",
  setCurrentWasteStationName: (name: string) =>
    set((state) => ({ ...state, currentWasteStationName: name })),
  separator: "",
  setSeparator: (separator: string) =>
    set((state) => ({ ...state, separator })),
  inspection: "",
  setInspection: (inspection: string) =>
    set((state) => ({ ...state, inspection })),
  setMenu: (menu: MenuItem[]) =>
    set((state) => ({
      ...state,
      menu,
    })),
  clearMenu: () =>
    set((state) => ({
      ...state,
      menu: [] as MenuItem[],
    })),
}));

interface MenuState {
  menu: MenuItem[];
  setMenu: (menu: MenuItem[]) => void;
  currentCustomerName: string;
  setCurrentCustomerName: (name: string) => void;
  currentWasteStationName: string;
  setCurrentWasteStationName: (name: string) => void;
  separator: string;
  setSeparator: (separator: string) => void;
  inspection: string;
  setInspection: (inspection: string) => void;
  clearMenu: () => void;
}

interface MenuItem {
  name: string;
  path: string;
}

export default menuStore;
