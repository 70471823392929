import { getSweref99 } from "helpers/coordinates/coordinates";
import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import { TransportSätt } from "types/tomning.type";
import {
  isValidAdressrad,
  isValidPostnummer,
  isValidSwerefEValue,
  isValidSwerefNValue,
} from "types/transport.type";
import { TransportDocument } from "types/transportDocument.type";
import { TransportPlaneringDTO } from "pages/app/naturvardsverket/ombuds-rapportering/ombudsRapportering.type";
import { hasAddress } from "../transport-rapportering/TransportRapporteringPage";
import { removeWhitespace } from "helpers/string";

export const validateLocation = (
  form: UseFormReturn<TransportPlaneringDTO>,
  data: TransportPlaneringDTO
) => {
  let hasError = false;
  // Startplats
  if (data.uppkomstplatsLocationType === "address") {
    if (!isValidPostnummer(data.uppkomstplats.adress?.postnummer)) {
      form.setError("uppkomstplats.adress.postnummer", {
        message: "Ange postnummer i formatet XXXXX",
      });
      hasError = true;
    }

    if (!isValidAdressrad(data.uppkomstplats.adress?.adressrad)) {
      form.setError("uppkomstplats.adress.adressrad", {
        message: "Adress är obligatoriskt",
      });
      hasError = true;
    }
  } else if (data.uppkomstplatsLocationType === "coordinate") {
    if (!isValidSwerefEValue(data.uppkomstplats.koordinat?.eposition)) {
      console.log(data.uppkomstplats.koordinat?.eposition);
      form.setError("uppkomstplats.koordinat.eposition", {
        message: "Ange koordinaten enligt SWEREF99, 6 siffror",
      });
      hasError = true;
    }
    if (!isValidSwerefNValue(data.uppkomstplats.koordinat?.nposition)) {
      form.setError("uppkomstplats.koordinat.nposition", {
        message: "Ange koordinaten enligt SWEREF99, 7 siffror",
      });
      hasError = true;
    }
  }

  // slutplats
  if (data.mottagningsplatsLocationType === "address") {
    if (!isValidPostnummer(data.mottagningsplats.adress?.postnummer)) {
      form.setError("mottagningsplats.adress.postnummer", {
        message: "Ange postnummer i formatet XXXXX",
      });
      hasError = true;
    }

    if (!isValidAdressrad(data.mottagningsplats.adress?.adressrad)) {
      form.setError("mottagningsplats.adress.adressrad", {
        message: "Adress är obligatoriskt",
      });
      hasError = true;
    }
  } else if (data.mottagningsplatsLocationType === "coordinate") {
    if (!isValidSwerefEValue(data.mottagningsplats.koordinat?.eposition)) {
      form.setError("mottagningsplats.koordinat.eposition", {
        message: "Ange koordinaten enligt SWEREF99, 6 siffror",
      });
      hasError = true;
    }
    if (!isValidSwerefNValue(data.mottagningsplats.koordinat?.nposition)) {
      form.setError("mottagningsplats.koordinat.nposition", {
        message: "Ange koordinaten enligt SWEREF99, 7 siffror",
      });
      hasError = true;
    }
  }
  return hasError;
};

type Koordinat = {
  nposition: number;
  eposition: number;
  beskrivning: string;
};

export const resetForm = (
  form: UseFormReturn<TransportPlaneringDTO>,
  td: TransportDocument,
  avfallFieldArray: UseFieldArrayReturn<TransportPlaneringDTO>
) => {
  const uppkomstplatsSweRefKoordinater = getSweref99(
    td.tomning.separator.coordinates
  );
  let uppkomstplatsKoordinater: Koordinat = {
    nposition: 0,
    eposition: 0,
    beskrivning: "",
  };
  let hasUppkomstplatsKoordinater = false;
  if (uppkomstplatsSweRefKoordinater) {
    hasUppkomstplatsKoordinater = true;
    uppkomstplatsKoordinater.nposition = uppkomstplatsSweRefKoordinater[0];
    uppkomstplatsKoordinater.eposition = uppkomstplatsSweRefKoordinater[1];
    uppkomstplatsKoordinater.beskrivning = "";
  }

  const mottagningsplatsSweRefKoordinater = getSweref99(
    td.wasteStation?.coordinates
  );
  let mottagningsplatsKoordinater: Koordinat = {
    nposition: 0,
    eposition: 0,
    beskrivning: "",
  };
  let hasMottagningsplatsKoordinater = false;
  if (mottagningsplatsSweRefKoordinater) {
    hasMottagningsplatsKoordinater = true;
    mottagningsplatsKoordinater.nposition =
      mottagningsplatsSweRefKoordinater[0];
    mottagningsplatsKoordinater.eposition =
      mottagningsplatsSweRefKoordinater[1];
    mottagningsplatsKoordinater.beskrivning = "";
  }

  const company = td.company;
  const customer = td.customer;
  const separator = td.tomning.separator;

  form.reset({
    cfarNR: separator.cfarNR ?? "",
    uppkomstplats: {
      kommunkod: separator.kommunKod,
      adress: {
        adressrad: separator.address,
        postnummer: separator.postalCode,
      },
      koordinat: hasUppkomstplatsKoordinater
        ? uppkomstplatsKoordinater
        : undefined,
    },
    uppkomstplatsLocationType:
      !hasAddress(separator) && hasUppkomstplatsKoordinater
        ? "coordinate"
        : "address",
    mottagningsplats: {
      kommunkod: td.wasteStation?.knCode || "",
      adress: {
        adressrad: td.wasteStation?.address || "",
        postnummer: (td.wasteStation?.postalCode || "").replace(" ", ""),
      },
      koordinat: hasMottagningsplatsKoordinater
        ? mottagningsplatsKoordinater
        : undefined,
    },
    mottagningsplatsLocationType:
      !hasAddress(td.wasteStation) && hasMottagningsplatsKoordinater
        ? "coordinate"
        : "address",
    nyInnehavare: td.wasteStation?.organizationNumber || "",
    verksamhetensKontaktpersonEpost: customer.contactEmail || "",
    verksamhetensKontaktpersonNamn: customer.contactName || "",
    verksamhetensKontaktpersonTelefonnummer: customer.contactPhone || "",
    verksamhetensNamn: customer.name,
    verksamhetsutovare: customer.organizationNumber,
    // transportor: company.organizationNumber,
    ombud: company.organizationNumber,
    ombudetsNamn: company.name,
    ombudetsKontaktpersonNamn: company.contactFullName,
    ombudetsKontaktpersonEpost: company.contactEmail,
    ombudetsKontaktpersonTelefonnummer: company.contactPhone,
    transportDocumentId: td.id,
    borttransportDatum: td.timeOfDeparture,
    transportsatt: TransportSätt.Vägtransport,
    referens: td.tomning.assignment.referenceNumber ?? "",
  });
  td.wasteList.forEach((waste) => {
    avfallFieldArray.append({
      kod: waste.wasteCode.code,
      mangd: !waste.weightFinal ? +waste.weightPreliminary : +waste.weightFinal,
      wasteUuid: waste.uuid,
    });
  });
};

export const tranformDataBeforeSubmit = (data: TransportPlaneringDTO) => ({
  ...data,
  cfarNR: data.cfarNR?.trim(),
  avfall: data.avfall.map((item) => ({ ...item, mangd: +item.mangd })),
  uppkomstplats: {
    kommunkod: data.uppkomstplats.kommunkod.trim(),
    adress:
      data.uppkomstplatsLocationType === "address"
        ? {
            ...data.uppkomstplats.adress,
            postnummer: removeWhitespace(data.uppkomstplats.adress?.postnummer),
            adressRad: data.uppkomstplats.adress?.adressrad?.trim(),
          }
        : undefined,
    koordinat:
      data.uppkomstplatsLocationType === "coordinate"
        ? {
            ...data.uppkomstplats.koordinat,
            nposition: Number(data.uppkomstplats.koordinat?.nposition),
            eposition: Number(data.uppkomstplats.koordinat?.eposition),
          }
        : undefined,
  },
  mottagningsplats: {
    kommunkod: data.mottagningsplats.kommunkod.trim(),
    adress:
      data.mottagningsplatsLocationType === "address"
        ? {
            ...data.mottagningsplats.adress,
            postnummer: removeWhitespace(
              data.mottagningsplats.adress?.postnummer
            ),
            adressRad: data.mottagningsplats.adress?.adressrad?.trim(),
          }
        : undefined,
    koordinat:
      data.mottagningsplatsLocationType === "coordinate"
        ? {
            ...data.mottagningsplats.koordinat,
            nposition: Number(data.mottagningsplats.koordinat?.nposition),
            eposition: Number(data.mottagningsplats.koordinat?.eposition),
          }
        : undefined,
  },
  transportor: data.ombud.replace("-", ""),
  nyInnehavare: data.nyInnehavare?.replace("-", "").trim(),
  verksamhetsutovare: data.verksamhetsutovare.replace("-", "").trim(),
  verksamhetensNamn: data.verksamhetensNamn.trim(),
  verksamhetensKontaktpersonNamn: data.verksamhetensKontaktpersonNamn.trim(),
  verksamhetensKontaktpersonEpost: data.verksamhetensKontaktpersonEpost.trim(),
  verksamhetensKontaktpersonTelefonnummer:
    data.verksamhetensKontaktpersonTelefonnummer.trim(),
  ombud: data.ombud.replace("-", "").trim(),
  ombudetsNamn: data.ombudetsNamn.trim(),
  ombudetsKontaktpersonNamn: data.ombudetsKontaktpersonNamn.trim(),
  ombudetsKontaktpersonEpost: data.ombudetsKontaktpersonEpost.trim(),
  ombudetsKontaktpersonTelefonnummer:
    data.ombudetsKontaktpersonTelefonnummer.trim(),
  referens: data.referens?.trim(),
});
