/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useRef } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { TabDisplayItemWrapper } from "./tab/TabDisplayItemWrapper";
import { TabDisplayItemLabel } from "./tab/TabDisplayItemLabel";
import { TabDisplayItemValue } from "./tab/TabDisplayItemValue";
import { TabDisplayList } from "./tab/TabDisplayList";
import { ModalContentWrapper } from "./ModalContentWrapper";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  actionText: string;
  actionHandler: () => void;
  title: string;
  isLoading?: boolean;
  separatorIdentification?: string;
  customerName: string;
}

// referensnumer
const DeleteSeparatorModal: React.FC<Props> = ({
  isOpen,
  onClose,
  actionText,
  actionHandler,
  title,
  isLoading = false,
  separatorIdentification = "Ej angett",
  customerName,
}) => {
  // const cancelButtonRef = useRef(null);

  const modalRef = useRef<HTMLDivElement>(null);
  const stopDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  useEffect(() => {
    modalRef?.current?.addEventListener("dragover", stopDrop);
    modalRef?.current?.addEventListener("drop", stopDrop);
    return () => {
      modalRef?.current?.removeEventListener("dragover", stopDrop);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      modalRef?.current?.removeEventListener("drop", stopDrop);
    };
  }, []);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        // initialFocus={cancelButtonRef}
        onClose={() => null}
        ref={modalRef}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative bg-white rounded-lg pt-2 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full p-2">
                <div>
                  <div>
                    <DialogTitle
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 sm:p-4"
                    >
                      {title}
                    </DialogTitle>

                    <div className="w-full border-t border-gray-200" />

                    <div className="sm:px-4 sm:pb-8">
                      <ModalContentWrapper>
                        <p>Är du säker på att du vill ta bort objektet?</p>
                        <TabDisplayList>
                          <TabDisplayItemWrapper className="sm:col-span-2">
                            <TabDisplayItemLabel>
                              Objektets Identifikation
                            </TabDisplayItemLabel>
                            <TabDisplayItemValue>
                              {separatorIdentification}
                            </TabDisplayItemValue>
                          </TabDisplayItemWrapper>
                          <TabDisplayItemWrapper className="sm:col-span-2">
                            <TabDisplayItemLabel>Kund</TabDisplayItemLabel>
                            <TabDisplayItemValue>
                              {customerName}
                            </TabDisplayItemValue>
                          </TabDisplayItemWrapper>
                        </TabDisplayList>
                      </ModalContentWrapper>
                    </div>
                  </div>
                </div>
                <div className="w-full border-t border-gray-200" />

                <div className="sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense sm:p-4 bg-white">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 disabled:bg-gray-400 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300 sm:col-start-2 sm:text-sm"
                    onClick={actionHandler}
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <svg
                        className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}

                    {actionText}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={onClose}
                    // ref={cancelButtonRef}
                  >
                    Avbryt
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteSeparatorModal;
