import { Radio, RadioGroup } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  ExtendedWasteStationNotification,
  useAcceptOrDeclineWasteStationNotification,
  useWasteStationNotificationByUuid,
  WasteStationNotificationQueryKey,
} from "api/wasteStationNotification.api";
import PrimaryButton from "components/buttons/PrimaryButton";
import { PageLoader } from "components/PageLoader";
import { Table, TBody, TD, TH, THead, TR } from "components/table";
import { TextArea } from "components/TextArea";
import { formatDate } from "helpers/date";
import { DatePickerNew } from "pages/app/aktuella-uppdrag/AddAssignmentModal/DatePickerNew";
import { ReactNode, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { WasteStationNotificationStatus } from "types/wasteStationNotification.type";
import { z } from "zod";
import Logo from "assets/Logo.png";
import { twMerge } from "tailwind-merge";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useQueryClient } from "react-query";
import { useNotification } from "hooks/useNotification";

const schema = z.object({
  acceptsRequestedDate: z
    .boolean()
    .nullable()
    .refine((val) => val === true || val === false, {
      message: "Vänligen välj ett alternativ",
    }),
  requestedDateChange: z.date().nullable().optional(),
  message: z.string().nullable().optional(),
});

export type AcceptOrDeclineFormData = z.infer<typeof schema>;

type Option = {
  value: boolean;
  label: string;
};
const options: Option[] = [
  { value: true, label: "Ja" },
  { value: false, label: "Nej" },
];

export const WasteStationNotificationPage = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code") ?? "";
  const { data, isError, isLoading } = useWasteStationNotificationByUuid(code);

  const mutation = useAcceptOrDeclineWasteStationNotification(code);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (mutation.isSuccess) {
      queryClient.invalidateQueries([
        WasteStationNotificationQueryKey.GetOneByUuid,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess]);

  const notification = useNotification();

  useEffect(() => {
    if (mutation.isError) {
      notification.error(
        "Något gick fel, försök igen. Om felet kvarstår rekommenderar vi er att kontakta lämnaren via telefon eller epost."
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isError]);

  const form = useForm<AcceptOrDeclineFormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      message: null,
      requestedDateChange: null,
      // comment: "",
    },
  });

  if (isLoading) {
    return <PageLoader />;
  }

  if (isError || !data) {
    return (
      <div className="h-screen w-screen flex flex-col justify-center items-center">
        <h1 className="text-[42px]/[50px]">Länken verkar inte vara giltig</h1>
      </div>
    );
  }

  const acceptsRequestedDate = form.watch("acceptsRequestedDate");

  const onSubmit = (data: AcceptOrDeclineFormData) => {
    mutation.mutate(data);
  };

  if (data.status === WasteStationNotificationStatus.Approved) {
    return (
      <Wrapper
        header="Aviseringen om avfall är bekräftad"
        data={data}
        acceptsRequestedDate={true}
      >
        {!!data.messageFromWasteStation && (
          <p>{data.messageFromWasteStation}</p>
        )}
      </Wrapper>
    );
  } else if (data.status === WasteStationNotificationStatus.Rejected) {
    return (
      <Wrapper
        acceptsRequestedDate={false}
        header="Aviseringen om avfall är avslagen"
        data={data}
      >
        <>
          {!!data.requestedDateChange && (
            <h3 className="text-sm font-semibold text-gray-900 mt-8">{`Nytt föreslaget lämningsdatum: ${formatDate(
              data.requestedDateChange
            )}`}</h3>
          )}
          {!!data.messageFromWasteStation && (
            <p className="text-sm font-normal text-gray-900 mt-4">
              {data.messageFromWasteStation}
            </p>
          )}
        </>
      </Wrapper>
    );
  }

  return (
    <Wrapper header={`Avisering om avfall`} data={data}>
      <>
        <Controller
          name="acceptsRequestedDate"
          control={form.control}
          render={({ field }) => (
            <RadioGroup
              value={field.value}
              onChange={field.onChange}
              className="my-8"
            >
              <label className="block text-sm font-medium text-gray-800">
                Accepterar du lämningsdatumet?
              </label>
              <div className="mt-2 space-y-2">
                {options.map((option) => (
                  <Radio
                    key={option.label}
                    value={option.value}
                    className={twMerge(
                      "group relative block cursor-pointer border rounded-lg border-gray-100 bg-white px-6 py-4 shadow-sm focus:outline-none data-[focus]:border-blue-600 data-[focus]:ring-2 data-[focus]:ring-blue-600 sm:flex sm:justify-between",
                      acceptsRequestedDate === true &&
                        option.value === true &&
                        "border-green-500",
                      acceptsRequestedDate === false &&
                        option.value === false &&
                        "border-rose-500"
                    )}
                  >
                    {({ checked }) => (
                      <div className="flex items-center">
                        {checked &&
                          (option.value ? (
                            <CheckCircleIcon className="h-5 w-5 text-green-500" />
                          ) : (
                            <XCircleIcon className="h-5 w-5 text-rose-500" />
                          ))}
                        {!checked && (
                          <span className="bg-white h-5 w-5 border rounded-full" />
                        )}
                        <span
                          className={clsx("ml-2", checked && "font-medium")}
                        >
                          {option.label}
                        </span>
                      </div>
                    )}
                  </Radio>
                ))}
              </div>
            </RadioGroup>
          )}
        />
        {form.formState.errors?.acceptsRequestedDate && (
          <p className="mt-2 text-sm text-red-600">
            {form.formState.errors.acceptsRequestedDate.message}
          </p>
        )}
        {/* {acceptsRequestedDate === false && ( */}
        <div className="inline-flex">
          <FormProvider {...form}>
            <DatePickerNew
              name="requestedDateChange"
              label="Föreslå nytt lämningsdatum (valfritt)"
              minDate={new Date()}
              disabled={acceptsRequestedDate !== false}
            />
          </FormProvider>
        </div>
        {/* )} */}
        <TextArea
          register={form.register}
          name="message"
          label="Meddelande (valfritt)"
          className="mt-6"
        />
        <PrimaryButton
          onClick={form.handleSubmit(onSubmit)}
          disabled={
            !(acceptsRequestedDate === true || acceptsRequestedDate === false)
          }
          className="mt-6 bg-blue-700 hover:bg-blue-600 focus:ring-blue-400"
          isLoading={mutation.isLoading}
        >
          Skicka
        </PrimaryButton>
      </>
    </Wrapper>
  );
};

type WrapperProps = {
  header: string;
  data: ExtendedWasteStationNotification;
  children: ReactNode;
  acceptsRequestedDate?: boolean | null;
};
const Wrapper = ({
  data,
  header,
  children,
  acceptsRequestedDate,
}: WrapperProps) => (
  <div className="h-screen w-screen flex flex-col justify-center items-center">
    <div className="flex-col sm:w-[800px] bg-white rounded-lg shadow-lg p-4 sm:p-6">
      <h1 className="text-2xl font-semibold text-gray-900 flex items-center justify-between">
        <span className="flex items-center">
          {header}
          {acceptsRequestedDate === true && (
            <CheckCircleIcon className="h-5 w-5 text-green-500 ml-2" />
          )}
          {acceptsRequestedDate === false && (
            <XCircleIcon className="h-5 w-5 text-rose-500 ml-2" />
          )}
        </span>
        <a href="https://www.driftjournalen.se" className="cursor-pointer">
          <img className="h-8 w-auto" src={Logo} alt="logo" />
        </a>
      </h1>
      <div className="text-gray-700 text-sm my-6">
        <h3 className="text-sm font-semibold text-gray-900">Lämnare</h3>
        <p>{data.assignment.company?.name}</p>
        <p>{data.assignment.company?.contactFullName}</p>
        <p>{data.assignment.company?.contactPhone}</p>
        <p>{data.assignment.company?.contactEmail}</p>
      </div>
      <p className="text-base font-semibold my-8">{`Lämningsdatum: ${formatDate(
        data.requestedDate
      )}`}</p>
      <div className="mb-6">
        {/* <p className="text-sm font-semibold mb-2">Avfall</p> */}
        <Table>
          <THead>
            <TR>
              <TH>Kod</TH>
              <TH>Uppskattad vikt</TH>
              <TH>Beskrivning</TH>
            </TR>
          </THead>
          <TBody>
            {data.preliminaryWeights.map(
              ({ code, preliminaryWeightInKilo, description }) => (
                <TR key={code}>
                  <TD>
                    <b>{code}</b>
                  </TD>
                  <TD>{`${preliminaryWeightInKilo} kg`}</TD>
                  <TD className="max-w-xs truncate">{description}</TD>
                </TR>
              )
            )}
          </TBody>
        </Table>
      </div>
      {children}
    </div>
  </div>
);
